<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <h1 class="text-center">Page not found</h1>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "index",
    components: {},
    data: () => ({}),
    methods: {}
  };
</script>

<style scoped></style>
